.AppLink {

}

.primary {
  color: var(--on-background);
}

.secondary {
  color: var(--inverted-on-background)
}
