.ThemeSwitcher {
  display: block;
  transition: scale 200ms;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.ThemeSwitcher:hover {
  scale: 0.8;
}
