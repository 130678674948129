.Button {
  cursor: pointer;
}

.clear {
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;
}
