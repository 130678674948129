.Sidebar {
  height: calc(100vh - var(--navbar-height));
  width: var(--sidebar-width);
  background: var(--secondary-variant);
  position: relative;
  transition: width 150ms;
}

.collapsed {
  width: var(--sidebar-width-collapsed);
}

.switchers {
  position: absolute;
  width: 100%;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.lang {
  color: var(--on-background);
  margin-left: 8px;
}
