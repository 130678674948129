@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import 'reset';
@import 'variables/global';
@import 'themes/dark';
@import 'themes/normal';

.app {
  font: var(--font-m);

  background: var(--background-color);
  color: var(--on-background);

  min-height: 100vh;
}

.content-page {
  display: flex;

}

.page-wrapper {
  flex-grow: 1;
  padding: 20px;
}
