.app.dark {
  --primary-color: #BB86FC;

  --primary-variant: #03700B3;

  --secondary-color: #03DAC6;

  --secondary-variant: #0b504f;

  --background-color: #121212;

  --surface-color: #121212;

  --error-color: #CF6679;

  --on-primary: #000000;

  --on-secondary: #000000;

  --on-background: #FFFFFF;

  --on-surface: #FFFFFF;

  --on-error: #000000;

  --inverted-primary-color: #6200EE;

  --inverted-primary-variant: #03700B3;

  --inverted-secondary-color: #03DAC6;

  --inverted-secondary-variant: #018786;

  --inverted-background-color: #FFFFFF;

  --inverted-surface-color: #FFFFFF;

  --inverted-error-color: #B00020;

  --inverted-on-primary: #FFFFFF;

  --inverted-on-secondary: #000000;

  --inverted-on-background: #000000;

  --inverted-on-surface: #000000;

  --inverted-on-error: #FFFFFF;
}
