.Navbar {
  width: 100%;
  height: var(--navbar-height);
  background: var(--secondary-variant);
  display: flex;
  align-items: center;
  padding: 20px;
}

.links {
  display: flex;
  gap: 15px;
  margin-left: auto;
}

